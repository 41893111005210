import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import LayoutComponent from "../components/shared/Layout";
import { get, isEmpty } from "lodash";
import BeforeLogin from "./BeforeLogin";
import CurrentNews from "../components/CurrentNews";
import HomeBanner from "../components/HomeBanner";
import { useNavigate } from "react-router-dom";
import BetSlipContext from "../context/BetSlipContext";

import Whatsapp from "../assets/images/whatapp.svg";

import popular1 from "../assets/images/popular-casino/crazytime.webp";
import popular2 from "../assets/images/popular-casino/supersicbo.webp";
import popular3 from "../assets/images/popular-casino/funkytime.webp";
import popular4 from "../assets/images/popular-casino/andarbahar.webp";
import popular5 from "../assets/images/popular-casino/cointoss.webp";
import popular6 from "../assets/images/popular-casino/dragontiger.webp";
import popular7 from "../assets/images/popular-casino/7up7down.webp";
import popular8 from "../assets/images/popular-casino/aviator.webp";
import popular9 from "../assets/images/popular-casino/crazycoinflip.webp";
import popular10 from "../assets/images/popular-casino/lightingroulette.webp";
import popular11 from "../assets/images/popular-casino/monopoly.webp";

const HomeNew = () => {
  const {
    messagelist,
    setAnnouncement,
    user,
    setGameTab,
    setActive,
    banner,
    setEvolutionModal,
  } = useContext(AuthContext);
  let { dolaunchCasinoNew, launchCasino, launchEGTCasino } =
    useContext(BetSlipContext);
  const navigate = useNavigate();

  return (
    <div>
      <LayoutComponent visibilityType={true}>
        {messagelist?.length > 0 && (
          <CurrentNews
            message={messagelist}
            setAnnouncement={setAnnouncement}
          />
        )}{" "}
        <HomeBanner messagelist={messagelist} banner={banner} />
        <BeforeLogin />
        <div className="position-relative gx-2 casino-row">
          <div className="main-slider-inner-div ">
            <h5 style={{ fontSize: "16px" }}>Popular Casino</h5>
          </div>
          <div className="homenew-outer mt-1">
            {gameArray?.map((res) => {
              return (
                <div
                  style={{ position: "relative" }}
                  onClick={() => {
                    if (isEmpty(user)) {
                      navigate("/login");
                    } else {
                      if (res?.platForm == "EVOLUTION") {
                        setEvolutionModal({
                          status: true,
                          data: res,
                        });
                      } else {
                        if (res?.newType == "launch") {
                          launchCasino({
                            platForm: res?.platForm || "",
                            gameType: res?.gameType || "",
                            casinoType: res?.casinoType || "",
                          });
                        } else if (isEmpty(res?.type)) {
                          if (res?.gameTypeCheck == "platForm") {
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                            });
                          } else if (res?.gameType == "egtGame") {
                            launchEGTCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameTypeNew,
                              casinoType: res?.casinoType,
                            });
                          } else if (!isEmpty(res?.gameid)) {
                            launchEGTCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                              gameid: res?.gameid,
                            });
                          } else {
                            if (
                              res?.platForm !== "" &&
                              res?.gameType !== "" &&
                              res?.casinoType !== ""
                            ) {
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                              });
                            }
                          }
                        } else {
                          dolaunchCasinoNew(
                            res?.game == "poker"
                              ? {
                                  gameCode: res?.gameCode,
                                  legacy: res?.legacy,
                                  type: res?.type,
                                }
                              : {
                                  gameCode: res?.gameCode,
                                  legacy: res?.legacy,
                                  type: res?.type,
                                }
                          );
                        }
                      }
                    }
                  }}
                >
                  <img src={res?.image} />
                  <p className="entrance-title">{res?.title}</p>
                </div>
              );
            })}
          </div>
        </div>
        <img
          src={Whatsapp}
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=+917301476666&text=")
          }
          style={{
            position: "fixed",
            bottom: "8%",
            left: "0%",
            zIndex: "99999",
          }}
        />
      </LayoutComponent>
    </div>
  );
};

export default HomeNew;

const gameArray = [
  {
    image: "../assets/images/poker/evoluation7.webp",
    title: "Evolution Lobby",
    gameCode: 103514,
    type: "sec",
    platForm: "EVOLUTION",
    gameType: "",
    casinoType: "EVOLUTION-LIVE-001",
    newType: "launch",
    isLobbyTrue: true,
  },

  {
    gameCode: 54230,
    image: "../assets/images/pragmatic-play.webp",
    title: "Pragmatic Lobby",
    type: "sec",
    platForm: "PP",
    gameType: "",
    casinoType: "",
    newType: "launch",
  },
  {
    gameCode: 54230,
    image: "../assets/images/banner_Baccarat-half.png",
    title: "Baccarat",
    type: "sec",
    gameTypeCheck: "platForm",
    casinoType: "JILI-TABLE-014",
    gameType: "TABLE",
    platForm: "JILI",
    newType: "launch",
  },
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Aura Lobby",
    gameCode: "98789",
    game: "poker",
    type: "first",
    legacy: "",
  },

  // {
  //   image: "../assets/images/poker/vivo2.webp",
  //   title: "Vivo Lobby",
  //   gameCode: "75695",
  //   type: "sec",
  // },
  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    game: "poker",
    type: "first",
    legacy: "98789_2",
  },

  {
    image: "../assets/images/poker/live1.webp",
    gameCode: "77409",
    title: "Plinkxo",
    type: "first",
  },
  {
    image: "../assets/images/poker/live2.webp",
    gameCode: "5268",
    title: "Cricket Live",
    type: "first",
  },

  {
    image: "../assets/images/poker/avaitrix.jpg",
    gameCode: "9999",
    title: "Aviatrix",
    legacy: "67722-1_8",
    type: "first",
  },
  {
    image: "../assets/images/poker/aviator1.webp",
    gameCode: "9999",
    title: "Aviator",
    legacy: "67722-2_8",
    type: "first",
  },

  // {
  //   image: "../assets/images/poker/DICE-7.webp",
  //   title: "Sicbo",
  //   gameCode: "21464",
  //   id: "RngSicbo00000001",
  //   type: "sec",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-19.webp",
  //   title: "Football Rouleete",
  //   gameCode: "21000",
  //   id: "frenchroulettene",
  //   type: "sec",
  // },

  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
    game: "poker",
    type: "first",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
    game: "poker",
    type: "first",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
    game: "poker",
    type: "first",
  },

  // {
  //   image: "../assets/images/poker/PT-LIVE-017.webp",
  //   title: "Casino Hold'em",
  //   gameCode: "21000",
  //   id: "casinoholdemne00",
  //   type: "sec",
  // },
  // {
  //   image: "../assets/images/poker/BACCARAT-7.webp",
  //   title: "Baccarat",
  //   gameCode: "21000",
  //   id: "baccarat2ne00000",
  //   type: "sec",
  // },

  {
    image: "../assets/images/poker/BACCARAT-7.webp",
    title: "Baccarat",
    gameCode: "92038",
    legacy: "92038_2",
    type: "first",
  },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-204.png",
  //   title: "Andhar Bahar",
  //   gameCode: "21296",
  //   type: "sec",
  //   id: "AndarBahar000001",
  // },
  {
    image: "../assets/images/poker/36.webp",
    title: "Andhar Bahar",
    gameCode: "87565",
    legacy: "87565_3",
    type: "first",
  },
  // {
  //   image: "../assets/images/poker/BACCARAT-5.webp",
  //   title: "Lightning Baccarat",
  //   type: "sec",
  //   gameCode: "21280",
  //   id: "rng-lbaccarat000",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   title: "Speed Roulette",
  //   gameCode: "21257",
  //   type: "sec",
  //   id: "SpeedAutoRo00001",
  // },

  {
    image: "../assets/images/poker/29.webp",
    title: "Speed Roulette",
    gameCode: "98792",
    legacy: "98792_3",
    type: "first",
  },

  // {
  //   image: "../assets/images/poker/GAME-SHOW-2.webp",
  //   title: "Cash or Crash",
  //   gameCode: "21254",
  //   type: "sec",
  //   id: "CashOrCrash00001",
  // },
  {
    image: "../assets/images/poker/39.webp",
    title: "Matka (Virtual)",
    gameCode: "92036",
    legacy: "92036_3",
    type: "first",
  },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-14.webp",
  //   title: "Mega Ball",
  //   gameCode: "21000",
  //   type: "sec",
  //   id: "MegaBall00000001",
  // },

  {
    image: "../assets/images/poker/38.webp",
    title: "Hi Low (Virtual)",
    gameCode: "56969",
    legacy: "56969_3",
    type: "first",
  },
  // {
  //   image: "../assets/images/poker/ROULETTE-11.webp",
  //   title: "Auto Roulette",
  //   gameCode: "21190",
  //   type: "sec",
  //   id: "01rb77cq1gtenhmo",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-18.webp",
  //   title: "Roulette",
  //   gameCode: "21027",
  //   type: "sec",
  //   id: "wzg6kdkad1oe7m5k",
  // },

  {
    image: "../assets/images/poker/14.webp",
    title: "Roulette",
    gameCode: "98788",
    legacy: "98788_2",
    type: "first",
  },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-009.webp",
  //   title: "Triple Card",
  //   type: "sec",
  //   gameCode: "21000",
  //   id: "n5emwq5c5dwepwam",
  // },
  {
    image: "../assets/images/poker/27.webp",
    title: "29 Card Baccarat",
    gameCode: "67690",
    legacy: "67690_2",
    type: "first",
  },

  // {
  //   image: "../assets/images/poker/vivo5.webp",
  //   gameCode: "75695",
  //   type: "sec",
  //   title: "Quenco Baccarat",
  // },
  // {
  //   image: "../assets/images/poker/vivo6.webp",
  //   gameCode: "82701",
  //   type: "sec",
  //   title: "Live Baccarat",
  // },
  // {
  //   image: "../assets/images/poker/vivo7.webp",
  //   gameCode: "82690",
  //   type: "sec",
  //   title: "Summaca Baccarat",
  // },
  // {
  //   image: "../assets/images/poker/vivo8.webp",
  //   gameCode: "82703",
  //   type: "sec",
  //   title: "Blackjack",
  // },

  // {
  //   image: "../assets/images/poker/vivo3.webp",
  //   gameCode: "250413",
  //   type: "sec",
  //   title: "Casino Hold'em",
  // },
  // {
  //   image: "../assets/images/poker/vivo5.webp",
  //   gameCode: "75695",
  //   type: "sec",
  //   title: "Baccarat",
  // },
  // {
  //   image: "../assets/images/poker/vivo9.webp",
  //   gameCode: "75694",
  //   type: "sec",
  //   title: "Blackjack",
  // },

  {
    id: 1,
    image: popular1,
    title: "Crazy Time",
    titlebn: "ক্রেজি টাইম",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    id: 1,
    image: popular2,
    title: "Super Sic Bo",
    titlebn: "সুপার সিকবো",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-005",
  },
  {
    id: 3,
    image: popular3,
    title: "Funky Time",
    titlebn: "ফানকি টাইম",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-183",
  },
  {
    id: 4,
    image: popular4,
    title: "Andar Bahar",
    titlebn: "আন্দার বাহার",
    gameTypeCheck: "platForm",
    gameType: "TABLE",
    platForm: "JILI",
    casinoType: "JILI-TABLE-003",
  },
  {
    id: 5,
    image: popular5,
    title: "Coin Toss",
    titlebn: "কয়েন টস",
    gameTypeCheck: "platForm",
    gameType: "TABLE",
    platForm: "KINGMAKER",
    casinoType: "KM-TABLE-036",
  },
  {
    id: 6,
    image: popular6,
    title: "Dragon Tiger",
    titlebn: "ড্রাগন টাইগার",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-034",
  },
  {
    id: 7,
    image: popular7,
    title: "7up7down",
    titlebn: "৭ আপ ৭ ডাউন",
    gameTypeCheck: "platForm",
    gameType: "TABLE",
    platForm: "JILI",
    casinoType: "JILI-TABLE-011",
  },
  // {
  //   id: 8,
  //   image: popular8,
  //   title: "Aviatar ",
  //   titlebn: "অ্যাভায়টর",
  //   gameTypeCheck: "platForm",
  //   gameType: "",
  //   platForm: "",
  //   casinoType: "",
  // },

  {
    id: 9,
    image: popular9,
    title: "Crazy Coin Flip",
    titlebn: "ক্রেজি কয়েন ফ্লিপ",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-014",
  },
  {
    image: "../assets/images/virtualCasino.webp",
    title: "Virtual",
    gameCode: 60001,
    type: "sec",
    platForm: "SABA",
    gameType: "VIRTUAL",
    casinoType: "SABA-VIRTUAL-001",
    newType: "launch",
  },
  {
    id: 10,
    image: popular10,
    title: "Lightning Roulette",
    titlebn: "লাইটেনিং রোলেট",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-040",
  },

  {
    id: 11,
    image: popular11,
    title: "MONOPOLY",
    titlebn: "মনোপলি",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-006",
  },
];
