import casinoIcon from "../assets/NewImages/icon-casino.png";
import slotIcon from "../assets/NewImages/icon-slot.png";
import TableIcon from "../assets/NewImages/icon-table.png";
import fishicon from "../assets/NewImages/icon-fish.png";
import crashIcon from "../assets/NewImages/icon-crash.png";
import lotteryIcon from "../assets/NewImages/icon-lottery.png";
import arcadeIcon from "../assets/NewImages/icon-arcade.png";
import spribe from "../assets/NewImages/SPRIBE.webp";
import Jili from "../assets/NewImages/jilii.png";
import fc from "../assets/NewImages/fc.png";
import jdb from "../assets/NewImages/jdb.png";
import km from "../assets/NewImages/km.png";
import pg from "../assets/NewImages/pg.png";
import pt from "../assets/NewImages/pt.png";
import rt from "../assets/NewImages/REDTIGER.webp";
import sexy from "../assets/NewImages/SEXYBCRT.webp";
import sg from "../assets/NewImages/SPADEGAMING.webp";
import evo from "../assets/NewImages/evo.png";
import sv388 from "../assets/NewImages/SV388.webp";
import pp from "../assets/NewImages/pp.png";
import yesbingo from "../assets/NewImages/provider-awcmyesbingo.png";
import netent from "../assets/NewImages/provider-netent.png";
import nlcLogo from "../assets/NewImages/nl-yellow.svg";
import betgames from "../assets/NewImages/BETGAMES.webp";
import esport from "../assets/NewImages/E1SPORT.webp";
import fastspin from "../assets/NewImages/FASTSPIN.webp";
import iconhotroad from "../assets/NewImages/hotroad.png";
import joker from "../assets/NewImages/provider-joker.png";
import p8 from "../assets/NewImages/provider-awcmp8.png";
import btg from "../assets/NewImages/btg.png";
import dragonsoft from "../assets/NewImages/DRAGONSOFT.webp";
import iconSportbook from "../assets/NewImages/icon-sportbook.png";
const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;

export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};
export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "."];
export const premiumCategoryArray = [
  { type: 1, name: "Popular" },
  { type: 3, name: "Match" },
  { type: 5, name: "Innings" },
  { type: 10, name: "Over" },
  { type: 2, name: "Batsman" },
  { type: 6, name: "Partnership" },
  { type: 7, name: "Group" },
  { type: 9, name: "Range" },
  { type: 11, name: "Head-to-Head" },
  { type: 8, name: "Player" },
];
export const priceData = [
  {
    id: 1,
    value: 10,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 100,
  },
  {
    id: 4,
    value: 200,
  },
  {
    id: 5,
    value: 1000,
  },
  {
    id: 6,
    value: 5000,
  },
];
export const headerData = [
  {
    class: "text-start",
    title: "Market",
    widht: "30%",
  },
  {
    class: "text-end",
    title: "Sports",
    widht: "",
  },
  {
    class: "text-end",
    title: "Selection",
    widht: "",
  },
  {
    class: "text-end",
    title: "Type",
    widht: "",
  },
  {
    class: "text-end",
    title: "Bet ID",
    widht: "",
  },
  {
    class: "text-end",
    title: "Bet placed",
    widht: "",
  },
  {
    class: "text-end",
    title: "Odds req.",
    widht: "",
  },
  {
    class: "text-end",
    title: "Matched",
    widht: "",
  },
  {
    class: "text-end",
    title: "Avg. odds matched",
    widht: "",
  },
  {
    class: "text-end",
    title: "Date matched",
    widht: "",
  },
];
export const betFairData = {
  data: [
    {
      bmi: "1.205777993",
      ip: 1,
      mi: 2572760,
      ms: 1,
      eti: "4",
      eid: "31859931",
      grt: "2022-11-01T08:20:58.203Z",
      rt: [
        {
          ri: 10301,
          rt: 1.49,
          bv: 9134.79,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.48,
          bv: 30747.88,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.47,
          bv: 16964.29,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.5,
          bv: 11305.92,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.51,
          bv: 72391.18,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.52,
          bv: 10997.18,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3,
          bv: 5652.96,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.98,
          bv: 43.56,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.96,
          bv: 36885.43,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 3.05,
          bv: 4641.48,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.1,
          bv: 14535.58,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.15,
          bv: 7993.98,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
      ],
    },
  ],
  messageType: "match_odds",
};
export const categoryArray = [
  { type: 1, name: "Market" },
  { type: 3, name: "Bookmakers" },
  { type: 5, name: "ManualOdds" },
  { type: 10, name: "Win Toss" },
  { type: 2, name: "Session" },
  { type: 6, name: "Over by Over Session Market" },
  { type: 7, name: "Player" },
  { type: 9, name: "Wicket" },
  { type: 11, name: "Bowler Session" },
  { type: 8, name: "Last Digit ODD" },
  { type: 4, name: "LineMarket" },
  { type: 14, name: "Premium ODDs" },
];
export const bookmakerJsonData = {
  data: [
    {
      bmi: 454109,
      ip: 1,
      mi: 2576457,
      ms: 1,
      eid: 31859931,
      grt: "2022-11-01T08:20:58.819Z",
      rt: [
        {
          ri: "4596251",
          rt: 1.49,
          bv: 520,
          pr: 0,
          af: 0,
          ib: true,
          pt: 0,
        },
        {
          ri: "4596251",
          rt: 1.51,
          bv: 537,
          pr: 0,
          af: 0,
          ib: false,
          pt: 0,
        },
        {
          ri: "4596252",
          rt: 2.13,
          bv: 520,
          pr: 0,
          af: 0,
          ib: true,
          pt: 0,
        },
        {
          ri: "4596252",
          rt: 2.14,
          bv: 537,
          pr: 0,
          af: 0,
          ib: false,
          pt: 0,
        },
      ],
    },
  ],
  messageType: "fancy",
};
export const fancyJsonData = [
  {
    _id: "636c853e02114d244506b413",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460152",
    selectionId: "4601521",
    centralizedId: "2576919",
    fancyName: "MATCH 1ST OVER RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460152,
        selectionID: 4601521,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460152,
      ip: 1,
      mi: 2576919,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:57:05.680Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b414",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460153",
    selectionId: "4601531",
    centralizedId: "2576920",
    fancyName: "6 OVER RUN IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460153,
        selectionID: 4601531,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460153,
      ip: 1,
      mi: 2576920,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:25:28.779Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b415",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460154",
    selectionId: "4601541",
    centralizedId: "2576921",
    fancyName: "6 OVER RUN ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460154,
        selectionID: 4601541,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460154,
      ip: 1,
      mi: 2576921,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:41.665Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b416",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460155",
    selectionId: "4601551",
    centralizedId: "2576922",
    fancyName: "20 OVER RUN IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460155,
        selectionID: 4601551,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460155,
      ip: 1,
      mi: 2576922,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.477Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b417",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460157",
    selectionId: "4601571",
    centralizedId: "2576923",
    fancyName: "20 OVER RUN ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460157,
        selectionID: 4601571,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460157,
      ip: 1,
      mi: 2576923,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:39.511Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b418",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460158",
    selectionId: "4601581",
    centralizedId: "2576924",
    fancyName: "FALL OF 1ST WKT IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460158,
        selectionID: 4601581,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460158,
      ip: 1,
      mi: 2576924,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:24.539Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b419",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460159",
    selectionId: "4601591",
    centralizedId: "2576925",
    fancyName: "FALL OF 1ST WKT ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460159,
        selectionID: 4601591,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460159,
      ip: 1,
      mi: 2576925,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.478Z",
      rt: [
        {
          ri: "4601591",
          rt: 24,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601591",
          rt: 24,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b41a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460160",
    selectionId: "4601601",
    centralizedId: "2576926",
    fancyName: "1ST 2 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460160,
        selectionID: 4601601,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460160,
      ip: 1,
      mi: 2576926,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.480Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460161",
    selectionId: "4601611",
    centralizedId: "2576927",
    fancyName: "1ST 2 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460161,
        selectionID: 4601611,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460161,
      ip: 1,
      mi: 2576927,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:35.892Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460162",
    selectionId: "4601621",
    centralizedId: "2576928",
    fancyName: "1ST 3 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460162,
        selectionID: 4601621,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460162,
      ip: 1,
      mi: 2576928,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.481Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460163",
    selectionId: "4601631",
    centralizedId: "2576929",
    fancyName: "1ST 3 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460163,
        selectionID: 4601631,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460163,
      ip: 1,
      mi: 2576929,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:32.976Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460164",
    selectionId: "4601641",
    centralizedId: "2576930",
    fancyName: "1ST 4 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460164,
        selectionID: 4601641,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460164,
      ip: 1,
      mi: 2576930,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.482Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460165",
    selectionId: "4601651",
    centralizedId: "2576931",
    fancyName: "1ST 4 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460165,
        selectionID: 4601651,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460165,
      ip: 1,
      mi: 2576931,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:28.613Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b420",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460166",
    selectionId: "4601661",
    centralizedId: "2576932",
    fancyName: "1ST WKT PSHIP BOUNDARIES IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460166,
        selectionID: 4601661,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460166,
      ip: 1,
      mi: 2576932,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:04.731Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b421",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460167",
    selectionId: "4601671",
    centralizedId: "2576933",
    fancyName: "1ST WKT PSHIP BOUNDARIES ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460167,
        selectionID: 4601671,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460167,
      ip: 1,
      mi: 2576933,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.483Z",
      rt: [
        {
          ri: "4601671",
          rt: 3,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601671",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b422",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460168",
    selectionId: "4601681",
    centralizedId: "2576934",
    fancyName: "1ST WKT LOST TO IND BALLS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460168,
        selectionID: 4601681,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460168,
      ip: 1,
      mi: 2576934,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:04.736Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b423",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460169",
    selectionId: "4601691",
    centralizedId: "2576935",
    fancyName: "1ST WKT LOST TO ENG BALLS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460169,
        selectionID: 4601691,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460169,
      ip: 1,
      mi: 2576935,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.484Z",
      rt: [
        {
          ri: "4601691",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601691",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b424",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460170",
    selectionId: "4601701",
    centralizedId: "2576936",
    fancyName: "R SHARMA RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460170,
        selectionID: 4601701,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460170,
      ip: 1,
      mi: 2576936,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.486Z",
      rt: [
        {
          ri: "4601701",
          rt: 42,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601701",
          rt: 42,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b425",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460171",
    selectionId: "4601711",
    centralizedId: "2576937",
    fancyName: "KL RAHUL RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460171,
        selectionID: 4601711,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460171,
      ip: 1,
      mi: 2576937,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:11.712Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b426",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460172",
    selectionId: "4601721",
    centralizedId: "2576938",
    fancyName: "J BUTTLER RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460172,
        selectionID: 4601721,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460172,
      ip: 1,
      mi: 2576938,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.487Z",
      rt: [
        {
          ri: "4601721",
          rt: 26,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601721",
          rt: 26,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b427",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460173",
    selectionId: "4601731",
    centralizedId: "2576939",
    fancyName: "A HALES RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460173,
        selectionID: 4601731,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460173,
      ip: 1,
      mi: 2576939,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.491Z",
      rt: [
        {
          ri: "4601731",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601731",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b428",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460174",
    selectionId: "4601741",
    centralizedId: "2576940",
    fancyName: "KL RAHUL BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460174,
        selectionID: 4601741,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460174,
      ip: 1,
      mi: 2576940,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:30.740Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b429",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460175",
    selectionId: "4601751",
    centralizedId: "2576941",
    fancyName: "R SHARMA BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460175,
        selectionID: 4601751,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460175,
      ip: 1,
      mi: 2576941,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.492Z",
      rt: [
        {
          ri: "4601751",
          rt: 6,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 115,
        },
        {
          ri: "4601751",
          rt: 6,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 85,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460176",
    selectionId: "4601761",
    centralizedId: "2576942",
    fancyName: "J BUTTLER BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460176,
        selectionID: 4601761,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460176,
      ip: 1,
      mi: 2576942,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.494Z",
      rt: [
        {
          ri: "4601761",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601761",
          rt: 5,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460177",
    selectionId: "4601771",
    centralizedId: "2576943",
    fancyName: "A HALES BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460177,
        selectionID: 4601771,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460177,
      ip: 1,
      mi: 2576943,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.495Z",
      rt: [
        {
          ri: "4601771",
          rt: 3,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601771",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460178",
    selectionId: "4601781",
    centralizedId: "2576944",
    fancyName: "H M B FACE BY KL RAHUL (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460178,
        selectionID: 4601781,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460178,
      ip: 1,
      mi: 2576944,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:11.009Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b42d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460179",
    selectionId: "4601791",
    centralizedId: "2576945",
    fancyName: "H M B FACE BY R SHARMA (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460179,
        selectionID: 4601791,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460179,
      ip: 1,
      mi: 2576945,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.499Z",
      rt: [
        {
          ri: "4601791",
          rt: 34,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601791",
          rt: 34,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460180",
    selectionId: "4601801",
    centralizedId: "2576946",
    fancyName: "H M B FACE BY J BUTTLER (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460180,
        selectionID: 4601801,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460180,
      ip: 1,
      mi: 2576946,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.500Z",
      rt: [
        {
          ri: "4601801",
          rt: 23,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601801",
          rt: 23,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460181",
    selectionId: "4601811",
    centralizedId: "2576947",
    fancyName: "H M B FACE BY A HALES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460181,
        selectionID: 4601811,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460181,
      ip: 1,
      mi: 2576947,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.501Z",
      rt: [
        {
          ri: "4601811",
          rt: 18,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601811",
          rt: 18,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b430",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460182",
    selectionId: "4601821",
    centralizedId: "2576948",
    fancyName: "TOTAL MATCH FOURS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460182,
        selectionID: 4601821,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460182,
      ip: 1,
      mi: 2576948,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:25.336Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b431",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460183",
    selectionId: "4601831",
    centralizedId: "2576949",
    fancyName: "TOTAL MATCH SIXES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460183,
        selectionID: 4601831,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460183,
      ip: 1,
      mi: 2576949,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:31.866Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b432",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460184",
    selectionId: "4601841",
    centralizedId: "2576950",
    fancyName: "TOTAL MATCH WKTS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460184,
        selectionID: 4601841,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460184,
      ip: 1,
      mi: 2576950,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:37.679Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b433",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460185",
    selectionId: "4601851",
    centralizedId: "2576951",
    fancyName: "TOTAL MATCH WIDES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460185,
        selectionID: 4601851,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460185,
      ip: 1,
      mi: 2576951,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:43.510Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b434",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460186",
    selectionId: "4601861",
    centralizedId: "2576952",
    fancyName: "TOTAL MATCH EXTRAS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460186,
        selectionID: 4601861,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460186,
      ip: 1,
      mi: 2576952,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:48.629Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b435",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460187",
    selectionId: "4601871",
    centralizedId: "2576953",
    fancyName: "TOTAL MATCH CAUGHT OUTS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460187,
        selectionID: 4601871,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460187,
      ip: 1,
      mi: 2576953,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:53.716Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b436",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460188",
    selectionId: "4601881",
    centralizedId: "2576954",
    fancyName: "TOTAL MATCH BOWLED (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460188,
        selectionID: 4601881,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460188,
      ip: 1,
      mi: 2576954,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:58.788Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b437",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460189",
    selectionId: "4601891",
    centralizedId: "2576955",
    fancyName: "TOTAL MATCH LBW (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460189,
        selectionID: 4601891,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460189,
      ip: 1,
      mi: 2576955,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:02.432Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b438",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460190",
    selectionId: "4601901",
    centralizedId: "2576956",
    fancyName: "TOTAL MATCH FIFTIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460190,
        selectionID: 4601901,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460190,
      ip: 1,
      mi: 2576956,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:06.789Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b439",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460191",
    selectionId: "4601911",
    centralizedId: "2576957",
    fancyName: "HIGHEST SCORING OVER IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460191,
        selectionID: 4601911,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460191,
      ip: 1,
      mi: 2576957,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:11.884Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460192",
    selectionId: "4601921",
    centralizedId: "2576958",
    fancyName: "TOP BATSMAN RUNS IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460192,
        selectionID: 4601921,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460192,
      ip: 1,
      mi: 2576958,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:16.930Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460193",
    selectionId: "4601931",
    centralizedId: "2576959",
    fancyName: "3 WKT OR MORE BY BOWLER IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460193,
        selectionID: 4601931,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460193,
      ip: 1,
      mi: 2576959,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:22.698Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460194",
    selectionId: "4601941",
    centralizedId: "2576960",
    fancyName: "6 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460194,
        selectionID: 4601941,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460194,
      ip: 1,
      mi: 2576960,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:17:45.863Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460195",
    selectionId: "4601951",
    centralizedId: "2576961",
    fancyName: "10 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460195,
        selectionID: 4601951,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460195,
      ip: 1,
      mi: 2576961,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.504Z",
      rt: [
        {
          ri: "4601951",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601951",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b43e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460196",
    selectionId: "4601961",
    centralizedId: "2576962",
    fancyName: "15 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460196,
        selectionID: 4601961,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460196,
      ip: 1,
      mi: 2576962,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.506Z",
      rt: [
        {
          ri: "4601961",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601961",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b43f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460197",
    selectionId: "4601971",
    centralizedId: "2576963",
    fancyName: "LAMBI RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460197,
        selectionID: 4601971,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460197,
      ip: 1,
      mi: 2576963,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.507Z",
      rt: [
        {
          ri: "4601971",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601971",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b440",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460198",
    selectionId: "4601981",
    centralizedId: "2576964",
    fancyName: "6 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460198,
        selectionID: 4601981,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460198,
      ip: 1,
      mi: 2576964,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.510Z",
      rt: [
        {
          ri: "4601981",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601981",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b441",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460199",
    selectionId: "4601991",
    centralizedId: "2576965",
    fancyName: "10 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460199,
        selectionID: 4601991,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460199,
      ip: 1,
      mi: 2576965,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.512Z",
      rt: [
        {
          ri: "4601991",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601991",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b442",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460200",
    selectionId: "4602001",
    centralizedId: "2576966",
    fancyName: "15 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460200,
        selectionID: 4602001,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460200,
      ip: 1,
      mi: 2576966,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.513Z",
      rt: [
        {
          ri: "4602001",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4602001",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b443",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460201",
    selectionId: "4602011",
    centralizedId: "2576967",
    fancyName: "LAMBI RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460201,
        selectionID: 4602011,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460201,
      ip: 1,
      mi: 2576967,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:38:50.120Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b444",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460202",
    selectionId: "4602021",
    centralizedId: "2576968",
    fancyName: "TOTAL MATCH BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460202,
        selectionID: 4602021,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460202,
      ip: 1,
      mi: 2576968,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:29.109Z",
      rt: [],
    },
  },
];
export const easyToGoCasino = [
  // {
  //   product: "EVOLUTION GAMING (S)",
  //   code: "1006",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_blackjack-half.jpg",
  // },
  // {
  //   product: "ORIENTAL GAMING",
  //   code: "98",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_supernowa-half.jpg",
  // },
  // {
  //   product: "GREATWALL",
  //   code: "23",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_7mojos-half.jpg",
  // },
  // {
  //   product: "918KAYA",
  //   code: "13",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_horsebook-half.jpg",
  // },
  // {
  //   product: "PLAYBOY",
  //   code: "82",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_minesweeper-half.jpg",
  // },
  // {
  //   product: "CLUBSUNCITY",
  //   code: "67",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_skytrader-half.jpg",
  // },
  {
    product: "GENESIS",
    code: "131",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_32card-half.jpg",
  },
  {
    product: "MICROGAMING",
    code: "35",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_rummy-half.jpg",
  },
  {
    product: "GAMINGSOFT SLOT",
    code: "2",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_binary-half.jpg",
  },
  // {
  //   product: "GAMEPLAY",
  //   code: "151",
  //   productType: "SLOT",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_andarBahar-half.jpg",
  // },
  {
    product: "KMG",
    code: "143",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_sicbo-half.jpg",
  },
  {
    id: 1,
    product: "KINGMAKER",
    code: "117",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_cardMatka-half.jpg",
  },
  {
    product: "CMD",
    code: "88",
    productType: "SPORTBOOK",
    productTypeCode: "3",
    image: "assets/images/home/banner_sevenUpDown-half.jpg",
  },
  {
    product: "1G Poker",
    code: "146",
    productType: "CARD AND BOARD",
    productTypeCode: "5",
    image: "assets/images/home/banner_bpoker-half.jpg",
  },
];

export const AmountArray = [
  { id: 1, amount: 300 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id: 6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];
export const DepositWithrawData = [
  {
    id: 1,
    icons: casinoIcon,
    title: "Indain Poker",
    titlebn: "ক্যাসিনো",
    type: "poker",
    position: "translate(-5px, 2px)",
  },
  {
    id: 2,
    icons: casinoIcon,
    title: "Casino",
    titlebn: "ক্যাসিনো",
    type: "casino",
    position: "translate(-5px, 2px)",
  },
  {
    id: 3,
    icons: slotIcon,
    title: "Slot",
    titlebn: "স্লট",
    type: "slot",
    position: "translate(-1px, -7px)",
  },

  {
    id: 4,
    icons: TableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table",
    position: "translate(-9px, 3px)",
  },
  {
    id: 6,
    icons: crashIcon,
    title: "Crash",
    titlebn: "ক্র্যাশ",
    type: "crash",
    position: "translate(-5px, 2px)",
  },

  {
    id: 5,
    icons: fishicon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fishing",
    position: "translate(-4px, -5px)",
  },

  {
    id: 5,
    icons: arcadeIcon,
    title: "Arcade",
    type: "arcade",
    titlebn: "স্পোর্টসবুক",
  },

  {
    id: 6,
    icons: lotteryIcon,
    title: "Lottery",
    titlebn: "ভার্চুয়াল",
    type: "lottery",
    position: "translate(-7px, 9px)",
  },
];
export const DepositWithrawDataArray = [
  {
    id: 1,
    title: "Crash",
    key: "crash",
    titlebn: "গরম খেল",
    array: [
      {
        image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "crash",
        platForm: "SPRIBE",
        gameType: "",
        casinoType: "",
      },
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "crash",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "crash",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "crash",
        gameType: "SLOT",
        platForm: "PP",
      },
    ],
  },
  {
    id: 2,
    title: "Live Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: evo,
        title: "EVOLUTION",
        type: "evo",
        key: "live",
        platForm: "EVOLUTION",
        gameType: "",
        casinoType: "EVOLUTION-LIVE-001",
        isLobbyTrue: true,
      },
      {
        image: sexy,
        title: "SEXYBCRT",
        type: "sexy",
        key: "live",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "",
        isLobbyTrue: true,
      },

      {
        image: iconhotroad,
        title: "HOTROAD",
        type: "hotroad",
        key: "live",
        platForm: "HOTROAD",
        gameType: "LIVE",
        casinoType: "",
        isLobbyTrue: true,
      },
      {
        image: pt,
        title: "PLAYTECH",
        type: "pt",
        key: "live",
        platForm: "PT",
        isLobbyTrue: true,
      },

      {
        image: betgames,
        title: "BG",
        type: "bg",
        key: "live",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "",
      },

      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "live",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "",
      },

      {
        image: esport,
        title: "E1SPORT",
        type: "e1sports",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "ESPORTS",
        platForm: "E1SPORT",
        casinoType: "",
      },

      {
        image: sv388,
        title: "SV388",
        type: "sv",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SV388",
        gameType: "LIVE",
        casinoType: "",
      },
    ],
  },

  {
    id: 2,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "slot",
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "slot",
        gameType: "SLOT",
        platForm: "PG",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        gameType: "SLOT",
        platForm: "JDB",
      },

      {
        image: fastspin,
        title: "FASTSPIN",
        type: "FastSpin",
        key: "slot",
        gameType: "SLOT",
        platForm: "FASTSPIN",
      },

      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "slot",
        gameType: "SLOT",
        platForm: "FC",
      },
      {
        image: sg,
        title: "SG",
        type: "spade",
        key: "slot",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "slot",
        gameType: "SLOT",
        platForm: "YESBINGO",
      },
      {
        image: rt,
        title: "RT",
        type: "rt",
        key: "slot",
        gameType: "",
        platForm: "RT",
      },
      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "slot",
        gameType: "SLOT",
        platForm: "PT",
      },

      {
        image: joker,
        title: "Joker",
        type: "Joker",
        key: "slot",
        gameType: "SLOT",
        platForm: "JOKER",
        casinoType: "",
      },

      {
        image: netent,
        title: "NETENT",
        type: "NETENT",
        key: "slot",
        gameType: "NETENT",
        platForm: "",
        casinoType: "",
      },

      {
        image: p8,
        title: "P8",
        type: "PLAYSTAR",
        key: "slot",
        gameType: "SLOT",
        platForm: "PLAY8",
        casinoType: "",
      },

      {
        image: btg,
        title: "BTG",
        type: "btg",
        key: "slot",
        gameType: "SLOT",
        platForm: "BTG",
      },

      {
        image: km,
        title: "KINGMAKER",
        type: "kingmaker",
        key: "slot",
        gameType: "SLOT",
        platForm: "KINGMAKER",
      },

      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "slot",
        gameType: "SLOT",
        platForm: "PP",
      },
      {
        image: dragonsoft,
        title: "DRAGOONSOFT",
        type: "dragoonsoft",
        key: "slot",
        gameType: "SLOT",
        platForm: "DRAGOONSOFT",
      },

      {
        image: nlcLogo,
        title: "NLC",
        type: "NLC",
        key: "SLOT",
        platForm: "NLC",
        gameType: "SLOT",
      },
    ],
  },
  {
    id: 3,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "table",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },

      {
        image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "table",
        platForm: "SPRIBE",
        gameType: "",
        casinoType: "",
      },

      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "TABLE",
        gameType: "SLOT",
        platForm: "PG",
        gameType: "TABLE",
      },

      {
        image: netent,
        title: "NETENT",
        type: "NETENT",
        key: "table",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "NETENT",
        casinoType: "",
      },
    ],
  },
  {
    id: 4,
    title: "Fishing",
    key: "fishing",
    type: "fishing",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fishing",
        gameType: "FH",
        platForm: "JILI",
      },

      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "fishing",
        gameType: "FH",
        platForm: "JDB",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fishing",
        gameType: "FH",
        platForm: "FC",
      },

      {
        image: joker,
        title: "Joker",
        type: "Joker",
        key: "fishing",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JOKER",
        casinoType: "",
      },

      {
        image: yesbingo,
        title: "YELLOWBAT",
        type: "yl",
        key: "fishing",
        gameType: "FH",
        platForm: "YESBINGO",
      },

      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "fishing",
        gameType: "FH",
        platForm: "SPADE",
      },
    ],
  },

  {
    id: 6,
    title: "Arcade",
    key: "arcade",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "arcade",
        // gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "JDB",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "arcade",
        // gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "FC",
      },
      {
        image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "arcade",
        // gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "SPRIBE",
        casinoType: "",
      },
    ],
  },
  {
    id: 7,
    title: "Lottery",
    key: "lottery",
    titlebn: "খেলাধুলা",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
      {
        image: joker,
        title: "Joker",
        type: "Joker",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JOKER",
        casinoType: "",
      },
      {
        image: iconSportbook,
        title: "SABAVIRTUAL",
        type: "SABAVIRTUAL",
        key: "lottery",
        // gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "",
        casinoType: "",
      },

      {
        image: yesbingo,
        title: "YellowBat",
        type: "yesbingo",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      },
    ],
  },
];
export const PokerGames2 = [
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Lobby",
    gameCode: "98789",
    legacy: "",
  },

  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },

  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/7.webp",
    title: "Poker",
    gameCode: "67564",
    legacy: "67564_2",
  },
  {
    image: "../assets/images/poker/8.webp",
    title: "32 Cards Casino",
    gameCode: "56967",
    legacy: "56967_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
    gameCode: "87564",
    legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/13.webp",
    title: "Six Player Poker",
    gameCode: "67565",
    legacy: "67565_2",
  },
  {
    image: "../assets/images/poker/14.webp",
    title: "Roulette",
    gameCode: "98788",
    legacy: "98788_2",
  },
  {
    image: "../assets/images/poker/15.webp",
    title: "Sic-bo",
    gameCode: "98566",
    legacy: "98566_2",
  },

  {
    image: "../assets/images/poker/17.webp",
    title: "Trio",
    gameCode: "67610",
    legacy: "67610_2",
  },
  {
    image: "../assets/images/poker/18.webp",
    title: "Baccarat",
    gameCode: "92038",
    legacy: "92038_2",
  },
  {
    image: "../assets/images/poker/19.webp",
    title: "Casino Meter",
    gameCode: "67575",
    legacy: "67575_2",
  },
  {
    image: "../assets/images/poker/20.webp",
    title: "Poker 20-20",
    gameCode: "67567",
    legacy: "67567_2",
  },
];
